<template>
  <div class="my-10">
    <div class="w-full mt-5">
      <div class="flex gap-3 px-5">
        <div class="w-3/12">Date</div>
        <div class="w-2/12">Fridge Number</div>
        <div class="w-2/12">Status</div>
        <div class="w-3/12">Location</div>
        <div class="w-2/12">Staff Name</div>
      </div>
      <div
        class="flex items-center gap-3 bg-white py-3 px-5 rounded-md my-4 hover:shadow-md"
        v-for="item in items"
        :key="item.id"
      >
        <div class="w-3/12 flex items-center gap-2">
          <calendar-icon class="opacity-30" />
          {{ parseDate(item.created_at) }}
        </div>
        <div class="w-2/12">{{ item.fridge_description }}</div>
        <div class="w-2/12">
          {{ item.fridge_status === "0" ? "Free" : "Taken" }}
        </div>
        <div class="w-3/12 flex items-center gap-2">
          <location-icon class="opacity-90" />
          {{ item.branch.branch_name }}
        </div>
        <div class="w-2/12">
          {{ item.staff.firstname }} {{ item.staff.lastname }}
        </div>
      </div>
    </div>
    <div class="flex justify-end gap-3" v-if="total">
      <template v-for="page in Math.ceil(total / perPage)">
        <div
          class="bg-white py-2 px-4 cursor-pointer rounded-md"
          :key="page"
          :class="
            page === currentPage
              ? 'bg-primary cursor-default text-white shadow-md'
              : ''
          "
          @click="$emit('fetchPage', page)"
        >
          {{ page }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { parseMoney } from "@/utils/helpers";

export default {
  name: "InventoryTable",
  components: {
    CalendarIcon: () => import("@/assets/icons/CalendarIcon"),
    LocationIcon: () => import("@/assets/icons/LocationIcon.vue"),
  },
  props: {
    allItems: {
      required: true,
      type: Array,
    },
    perPage: {
      required: false,
    },
    total: {
      required: false,
    },
    currentPage: {
      required: false,
    },
    type: {
      required: false,
      type: String,
      default: "all",
    },
  },
  methods: {
    exportData() {
      const objArray = this.exportedData;
      // https://stackoverflow.com/a/64663550/5454933
      var arr = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
      var str =
        `${Object.keys(arr[0])
          .map((value) => `"${value}"`)
          .join(",")}` + "\r\n";
      var csvContent = arr.reduce((st, next) => {
        st +=
          `${Object.values(next)
            .map((value) => `"${value}"`)
            .join(",")}` + "\r\n";
        return st;
      }, str);
      var element = document.createElement("a");
      element.href = "data:text/csv;charset=utf-8," + encodeURI(csvContent);
      element.target = "_blank";
      element.download = "export.csv";
      element.click();
    },
    parseDate(date) {
      return moment(date).format("D MMM, YYYY");
    },
    parseCash(value) {
      return parseMoney(value);
    },
  },
  computed: {
    items() {
      return this.allItems;
    },
    exportedData() {
      let dat = [];

      this.items.forEach((item) => {
        if (item.selected) {
          dat.push(item);
        }
      });

      return dat;
    },
  },
};
</script>
